<template>
  <v-data-table
    :headers="headers"
    :items="listAccountNumber"
    sort-by="no"
    dense
    @click:row="onClickRow"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="success" v-on="on" dark rounded :to="{ name: 'Create Account Number' }">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Create</span>
        </v-tooltip>
      </v-toolbar>
    </template>
    <template v-slot:item.no="{ item }">
      {{ listAccountNumber.map(x => x).indexOf(item) + 1 }}
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    headers: [
      {
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Account Number",
        value: "accountNumber",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Bank Name",
        value: "bankName",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Owner Name",
        value: "ownerName",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
  }),

  computed: {
    ...mapState("accountNumber", ["listAccountNumber"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.dispatch("accountNumber/getAll");
    },
    onClickRow(item) {
      this.$router.push({ name: "Detail Account Number", params: { id: item.id } });
    },
  },
};
</script>

<style></style>
